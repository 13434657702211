import { OrderRO } from "@ub/order-client"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { create } from "zustand"

import { BasketData } from "@/lib/intershop/interfaces/basket/basket"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { BasketLineItemData, BasketPromotionCode } from "@/lib/storefront/basket/basket"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { RecoData } from "@/lib/storefront/reco/reco"

interface UbStorefrontState {
  isB2BCustomer: boolean
  setIsB2BCustomer: (isB2BCustomer: boolean) => void
  addToBasketDialogVisible: boolean
  setAddToBasketDialogVisible: (visible: boolean) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  loginFormUsername: string | undefined
  setLoginFormUsername: (loginFormUsername: string | undefined) => void
  loginFormPassword: string | undefined
  setLoginFormPassword: (loginFormPassword: string | undefined) => void
  userData?: UserData
  setUserData: (customerData: UserData | undefined) => void
  basketData?: BasketData
  basketLineItems?: BasketLineItemData[]
  basketPromotionCodes?: BasketPromotionCode[]
  setBasketData: (basket: BasketData) => void
  setBasketLineItems: (basketLineItems: BasketLineItemData[] | undefined) => void
  setBasketPromotionCodes: (basketPromotionCodes: BasketPromotionCode[] | undefined) => void
  productDataAddedToBasket: ReducedProductData | undefined
  setProductDataAddedToBasket: (productData: ReducedProductData | undefined) => void

  productDataAddedToBasketPrices: ProductPriceContainerCtnrROV1 | undefined
  setProductDataAddedToBasketPrices: (productPrices: ProductPriceContainerCtnrROV1 | undefined) => void
  filterShown: boolean
  setFilterShown: (filterShown: boolean) => void

  crosssellingProductDataAddedToBasket: RecoData[]
  setCrosssellingProductDataAddedToBasket: (productData: RecoData[]) => void

  createdOrder?: OrderRO

  setCreatedOrder: (createdOrder: OrderRO | undefined) => void
}

export const useUbStorefrontState = create<UbStorefrontState>((set) => ({
  isB2BCustomer: true,
  // setIsB2BCustomer is called from UBHeaderClient to make is accessible from "everywhere".
  setIsB2BCustomer: (newIsB2BCustomer: boolean) => set(() => ({ isB2BCustomer: newIsB2BCustomer })),

  addToBasketDialogVisible: false,
  setAddToBasketDialogVisible: (visible: boolean) => set(() => ({ addToBasketDialogVisible: visible })),

  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  filterShown: false,
  setFilterShown: (filterShown: boolean) => set(() => ({ filterShown })),

  loginFormUsername: undefined,
  setLoginFormUsername: (loginFormUsername: string) => set(() => ({ loginFormUsername: loginFormUsername })),

  loginFormPassword: undefined,
  setLoginFormPassword: (loginFormPassword: string) => set(() => ({ loginFormPassword: loginFormPassword })),

  userData: undefined,
  setUserData: (userData: UserData) => set(() => ({ userData: userData })),

  basketData: undefined,
  setBasketData: (basketData: BasketData) => set(() => ({ basketData: basketData })),

  createdOrder: undefined,
  setCreatedOrder: (createdOrder: OrderRO) => set(() => ({ createdOrder: createdOrder })),

  basketLineItems: undefined,
  setBasketLineItems: (basketLineItems: BasketLineItemData[]) => set(() => ({ basketLineItems: basketLineItems })),

  basketPromotionCodes: undefined,
  setBasketPromotionCodes: (basketPromotionCodes: BasketPromotionCode[]) =>
    set(() => ({ basketPromotionCodes: basketPromotionCodes })),

  productDataAddedToBasket: undefined,
  setProductDataAddedToBasket: (productData: ReducedProductData | undefined) =>
    set(() => ({ productDataAddedToBasket: productData })),

  productDataAddedToBasketPrices: undefined,
  setProductDataAddedToBasketPrices: (productPrices: ProductPriceContainerCtnrROV1 | undefined) =>
    set(() => ({ productDataAddedToBasketPrices: productPrices })),

  crosssellingProductDataAddedToBasket: [],
  setCrosssellingProductDataAddedToBasket: (recoData: RecoData[]) =>
    set(() => ({ crosssellingProductDataAddedToBasket: recoData })),
}))
