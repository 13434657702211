"use client"

import React, { useState } from "react"
import { addArticleToCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ShoppingCart } from "lucide-react"
import { useTranslations } from "next-intl"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { Button, Input } from "@/components/ui/components"

interface BasketQuickOrderProps {
  isB2B: boolean
}

export default function BasketQuickOrder(props: BasketQuickOrderProps) {
  const t = useTranslations("storefront")

  const [productId, setProductId] = useState<string | undefined>(undefined)

  const { setBasketData } = useUbStorefrontState()

  function updateBasketData(productId: string) {
    if (productId) {
      addArticleToCurrentBasketAction(productId, 1).then((basketData) => {
        setBasketData(basketData)
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setProductId(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (productId && productId !== "") {
      updateBasketData(productId)
    }
  }

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-lg font-bold"}>Schnellbestellung</h2>
        <form className={"flex flex-row items-center gap-2"} onSubmit={handleSubmit}>
          <Input type="text" name="productId" value={productId} onChange={handleChange} />

          <Button aria-label={t("product.add_to_basket")} size={"productCardIcon"} onClick={handleSubmit}>
            <ShoppingCart className={"size-4"} />
          </Button>
        </form>
      </div>
    </>
  )
}
