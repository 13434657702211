import { useTranslations } from "next-intl"

import { BasketContext } from "@/lib/storefront/basket/basket"

interface BasketRecentlyViewedProductsProps {}

export default function BasketRecentlyViewedProducts(props: BasketRecentlyViewedProductsProps) {
  const t = useTranslations("storefront")

  return (
    <>
      <div>{/*Basket Recently Viewed*/}</div>
    </>
  )
}
