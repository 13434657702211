import { ExtendedAddressRO } from "@/lib/storefront/checkout/checkout"

interface AddressCardProps {
  address: ExtendedAddressRO
}

const OptionalSpan = ({ value }) => (value ? <span className="text-nowrap">{value}</span> : null)
const OptionalDiv = ({ value }) => (value ? <div className="text-nowrap">{value}</div> : null)

export function AddressCard(props: AddressCardProps) {
  const a = props.address

  return (
    <div className="flex flex-col gap-y-px text-sm">
      <div className="flex flex-row gap-x-[0.2rem]">
        <OptionalSpan value={a.title} />
        {/*<OptionalSpan value={a.aristocraticTitle} />*/}
        {/*<OptionalSpan value={a.jobTitle} />*/}
        <OptionalSpan value={a.honorific} />
        <OptionalSpan value={a.firstName} />
        {/*<OptionalSpan value={a.secondName} />*/}
        <OptionalSpan value={a.lastName} />
        {/*<OptionalSpan value={a.secondLastName} />*/}
      </div>
      <OptionalDiv value={a.companyName1} />
      <OptionalDiv value={a.companyName2} />
      <OptionalDiv value={a.addressLine1} />
      <OptionalDiv value={a.addressLine2} />
      {/*<OptionalDiv value={a.addressLine3} />*/}
      {/*<OptionalDiv value={a.postBox} />*/}
      {/*<OptionalDiv value={a.mainDivision} />*/}
      {/*<OptionalDiv value={a.mainDivisionName} />*/}
      {/*<OptionalDiv value={a.subDivision} />*/}
      {/*<OptionalDiv value={a.subDivisionName} />*/}
      <div className="flex flex-row gap-x-[0.2rem]">
        {/*<OptionalSpan value={a.countryCode} />*/}
        <OptionalSpan value={a.postalCode} />
        <OptionalSpan value={a.city} />
      </div>
      <OptionalSpan value={a.country} />
      <OptionalDiv value={a.email} />
      {/*<OptionalDiv value={a.phoneMobile} />*/}
      {/*<OptionalDiv value={a.phoneHome} />*/}
      <OptionalDiv value={a.phoneBusiness} />
      {/*<OptionalDiv value={a.phoneBusinessDirect} />*/}
      {/*<OptionalDiv value={a.fax} />*/}
    </div>
  )
}
