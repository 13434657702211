//actions that can be called from client but run on server!
"use server"

import { headers } from "next/headers"
import { OrderRO } from "@ub/order-client"

import { getAccessTokenFromNextHeader } from "@/lib/intershop/access-token-helper"
import { get } from "@/lib/intershop/api-client"
import { BasketData, BasketItems } from "@/lib/intershop/interfaces/basket/basket"
import { UserData } from "@/lib/intershop/interfaces/user/user"
import { logger } from "@/lib/logger"
import { BasketLineItemData, BasketPromotionCode } from "@/lib/storefront/basket/basket"
import {
  addArticlesToCurrentBasket,
  addPromotionCode,
  computeEcoTax,
  deleteBasketLineItem,
  fetchCurrentOrCreateBasket,
  loadBasketLineItems,
  loadBasketPromotionCodes,
  mergeBasket,
  removePromotionCode,
  updateBasketItemQuantity,
} from "@/lib/storefront/basket/basket-service"
import { createOrderFromCurrentBasket } from "@/lib/storefront/order/order-service"
import { ExtendedProductPriceContainer, ReducedProductData } from "@/lib/storefront/product/product"
import { fetchPricesForProductLegacy, fetchProduct } from "@/lib/storefront/product/product-service"
import { RecoData } from "@/lib/storefront/reco/reco"
import { getRecommendationsForProduct } from "@/lib/storefront/reco/reco-service"
import { getSystemConfigValue } from "@/lib/system-config-loader"

export async function createOrderFromCurrentBasketAction(): Promise<OrderRO | undefined> {
  logger.debug(`createOrderFromCurrentBasketAction()`)

  const createdOrder: OrderRO | undefined = await createOrderFromCurrentBasket()

  logger.logObject("debug", `createOrderFromCurrentBasketAction(): createdOrder`, { depth: 4 })
  return createdOrder
}

export async function removePromoCodeFromCurrentBasketAction(
  promoCode: string
): Promise<{ result: any; message: string; basketData: BasketData }> {
  logger.debug(`removePromoCodeFromCurrentBasketAction(${promoCode})`)

  try {
    const result = await removePromotionCode(promoCode)
    const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()
    if (!result?.errors) {
      await computeEcoTax(currentBasketData)

      logger.logObject("debug", `removePromoCodeFromCurrentBasketAction(${promoCode}): currentBasketData`, { depth: 4 })
      return { result: "OK", message: "OK", basketData: currentBasketData }
    } else {
      console.dir(result.errors, { depth: 4 })
      return {
        result: "NOK",
        message:
          result.errors && result.errors.length > 0
            ? result?.errors[0] && result?.errors[0].causes && result?.errors[0].causes[0].message
              ? result?.errors[0].causes[0].message
              : ""
            : "",
        basketData: currentBasketData,
      }
    }
  } catch (error) {
    const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()
    return { result: "NOK", message: "Fehler", basketData: currentBasketData }
  }
}

export async function addPromoCodeToCurrentBasketAction(
  promoCode: string
): Promise<{ result: any; message: string; basketData: BasketData }> {
  logger.debug(`addPromoCodeToCurrentBasketAction(${promoCode})`)

  try {
    const result = await addPromotionCode(promoCode)
    const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()
    if (!result?.errors) {
      await computeEcoTax(currentBasketData)

      logger.logObject("debug", `addPromoCodeToCurrentBasketAction(${promoCode}): currentBasketData`, { depth: 4 })
      return { result: "OK", message: "OK", basketData: currentBasketData }
    } else {
      console.dir(result.errors, { depth: 4 })
      return {
        result: "NOK",
        message:
          result.errors && result.errors.length > 0
            ? result?.errors[0] && result?.errors[0].causes && result?.errors[0].causes[0].message
              ? result?.errors[0].causes[0].message
              : ""
            : "",
        basketData: currentBasketData,
      }
    }
  } catch (error) {
    const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()
    return { result: "NOK", message: "Fehler", basketData: currentBasketData }
  }
}

export async function addArticleToCurrentBasketAction(article: string, quantity: number): Promise<BasketData> {
  logger.debug(`addArticleToCurrentBasketAction(${article}, ${quantity})`)

  await addArticlesToCurrentBasket(article, quantity)
  const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()

  await computeEcoTax(currentBasketData)

  logger.logObject(
    "debug",
    `addArticleToCurrentBasketAction(${article}, ${quantity}): currentBasketData...lineItems`,
    currentBasketData.included?.lineItems,
    { depth: 4 }
  )
  return currentBasketData
}

export async function updateBasketItemQuantityAction(
  article: string,
  quantity: number
): Promise<BasketData | undefined> {
  const updatedBasket = await updateBasketItemQuantity(article, quantity)

  await computeEcoTax(updatedBasket)

  return updatedBasket
}

export async function deleteLineItemAction(lineItemId: string): Promise<BasketData | undefined> {
  const updatedBasket = await deleteBasketLineItem(lineItemId)

  await computeEcoTax(updatedBasket)

  return updatedBasket
}

export async function determineCrosssellingProductsAction(article: string): Promise<RecoData[]> {
  let result: RecoData[] = []

  const load_crossselling_products: string = getSystemConfigValue("load_crossselling_products")
  if (load_crossselling_products === "true") {
    result = await getRecommendationsForProduct(article)
  }
  return result
}

export async function getBasketItems(id: string): Promise<BasketItems | undefined> {
  return get<BasketItems>(`baskets/${id}/items`)
}

export async function loadBasketLineItemsAction(
  isB2B: boolean,
  user: UserData | undefined,
  basketData: BasketData
): Promise<BasketLineItemData[] | undefined> {
  return await loadBasketLineItems(isB2B, user, basketData)
}

export async function loadBasketPromotionCodesAction(): Promise<BasketPromotionCode[] | undefined> {
  return await loadBasketPromotionCodes()
}

export async function mergeCurrentBasket(
  sourceBasketId: string | undefined,
  sourceApiToken: string
): Promise<BasketData> {
  const loggedInBasket: BasketData = await fetchCurrentOrCreateBasket()

  let mergedBasket: BasketData | undefined

  if (sourceBasketId) {
    mergedBasket = await mergeBasket(sourceBasketId, sourceApiToken, loggedInBasket.data?.id)
  } else {
    mergedBasket = loggedInBasket
  }
  await computeEcoTax(mergedBasket)
  return mergedBasket
}

export async function getApiToken(): Promise<string> {
  return await getAccessTokenFromNextHeader()
}

export async function fetchProductAction(article: string): Promise<ReducedProductData | undefined> {
  return await fetchProduct(article)
}

export async function fetchExtendedPricesAction(article: string): Promise<ExtendedProductPriceContainer | undefined> {
  return await fetchPricesForProductLegacy(article, headers())
}
