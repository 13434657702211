"use client"

import React, { FC, Fragment, useRef, useState } from "react"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { verifyReCaptcha } from "@/actions/reCaptchaActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { renderToStaticMarkup } from "react-dom/server"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { SubmitHandler, useForm } from "react-hook-form"
import {
  MARK_BOLD,
  MARK_LINK,
  NODE_HEADING,
  NODE_PARAGRAPH,
  render,
  StoryblokRichtext,
} from "storyblok-rich-text-react-renderer"
import { z } from "zod"

import { sendContactEmail } from "@/lib/email/smtp-client"
import { isDevMode, isProdMode } from "@/lib/nodeHelper"
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  Textarea,
} from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"
import EmailTemplateContact from "@/components/email-templates/EmailTemplateContact"
import StoryblokAssetClient from "@/components/storyblok/common/StoryblokAssetClient"
import { AssetStoryblok, RichtextStoryblok } from "@/components/storyblok/component-types"

// settings for development purposes
const DEV_INSERT_DEFAULTS = isDevMode()
const DEV_SHOW_EMAIL_TEMPLATE = false
const DEV_SEND_EMAILS = isProdMode()
const DEV_EMAIL = process.env.NEXT_PUBLIC_DEV_EMAIL // env setting for inserting default value in email field

interface UbContactUsGenericFormProps {
  fromEmail: string
  toEmail: string
  emailSubject: string
  emailImage?: AssetStoryblok
  emailHtmlBody: RichtextStoryblok
  emailTextBody: string
  emailFooterLeft: RichtextStoryblok
  emailFooterRight: RichtextStoryblok
  emailFooterBottom: RichtextStoryblok
  title?: RichtextStoryblok
  image?: AssetStoryblok
  description: RichtextStoryblok
  topic: string
  legalConsentNotification: RichtextStoryblok
  captchaHint: RichtextStoryblok
  httpRedirect: string
  httpRedirectError: string
  showExtended: boolean
  extendedOptions: any
}

const renderedRichText = (richtext: StoryblokRichtext | unknown) => {
  return render(richtext, {
    nodeResolvers: {
      [NODE_HEADING]: (children, props) => {
        switch (props.level) {
          case 1:
            return <h1 className="mb-4 text-4xl font-bold">{children}</h1>
          case 2:
            return <h2 className="mb-3 text-3xl font-semibold">{children}</h2>
          case 3:
            return <h3 className="mb-2 text-2xl font-semibold">{children}</h3>
          case 4:
            return <h4 className="mb-2 text-xl font-medium">{children}</h4>
          case 5:
            return <h5 className="mb-1 text-lg font-medium">{children}</h5>
          case 6:
            return <h6 className="mb-1 text-base font-medium">{children}</h6>
          default:
            return <div>{children}</div> // In case level is not 1-6, return a default div
        }
      },
      [NODE_PARAGRAPH]: (children) => {
        return <Fragment>{!children ? <br /> : <>{children}</>}</Fragment>
      },
    },
    markResolvers: {
      [MARK_BOLD]: (children) => <span className={"font-bold"}>{children}</span>,
      [MARK_LINK]: (children, { linktype, href }) => {
        if (linktype === "story" && href) {
          return (
            <Link prefetch={false} className="text-primary" href={href} target="_blank">
              {children}
            </Link>
          )
        }
        if (linktype === "url" && href) {
          return (
            <a className="text-primary" href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        }
        return (
          <a className={"text-primary"} href={"#"}>
            {children}
          </a>
        )
      },
    },
  })
}

const UbContactUsGenericFormClient: FC<UbContactUsGenericFormProps> = (props: UbContactUsGenericFormProps) => {
  const formRef = useRef<HTMLFormElement>(null)
  const router = useRouter()
  const t = useTranslations("storefront")
  const { isB2BCustomer } = useUbStorefrontState()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [sending, setSending] = useState(false)

  const validationSchema = z.object({
    ContactUsGenericForm_ExtendedTopic: z.string().optional(),
    ContactUsGenericForm_Title: z.enum([t("salutation.ms"), t("salutation.mr"), t("salutation.divers")], {
      required_error: t("forms.contact_us_generic_form.error.title"),
    }),
    ContactUsGenericForm_FirstName: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.first_name"))
      .max(64, t("forms.contact_us_generic_form.error.first_name_max")),
    ContactUsGenericForm_LastName: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.last_name"))
      .max(64, t("forms.contact_us_generic_form.error.last_name_max")),
    ContactUsGenericForm_Phone: z
      .string()
      .min(2, t("forms.contact_us_generic_form.error.phone"))
      .max(32, t("forms.contact_us_generic_form.error.phone_max")),
    ContactUsGenericForm_Email: z.string().email(t("forms.contact_us_generic_form.error.email")),
    ContactUsGenericForm_CustomerId: z
      .string()
      .max(32, t("forms.contact_us_generic_form.error.customer_id_max"))
      .optional(),
    ContactUsGenericForm_CompanyName: isB2BCustomer
      ? z
          .string()
          .min(2, t("forms.contact_us_generic_form.error.company_name"))
          .max(64, t("forms.contact_us_generic_form.error.company_name_max"))
      : z.string().optional(),
    ContactUsGenericForm_Comments: z.string().max(256, t("forms.contact_us_generic_form.error.comments_max")),
    ContactUsGenericForm_AcceptTerms: z.literal<boolean>(true, {
      errorMap: () => ({
        message: t("forms.contact_us_generic_form.error.terms"),
      }),
    }),
  })

  type ValidationSchemaType = z.infer<typeof validationSchema>

  const form = useForm<ValidationSchemaType>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      ContactUsGenericForm_Title: DEV_INSERT_DEFAULTS ? "Herr" : undefined,
      ContactUsGenericForm_FirstName: DEV_INSERT_DEFAULTS ? "John" : "",
      ContactUsGenericForm_LastName: DEV_INSERT_DEFAULTS ? "Doe" : "",
      ContactUsGenericForm_Phone: DEV_INSERT_DEFAULTS ? "123456" : "",
      ContactUsGenericForm_Email: DEV_INSERT_DEFAULTS ? DEV_EMAIL : "",
      ContactUsGenericForm_CompanyName: DEV_INSERT_DEFAULTS ? "ACME Company" : "",
      ContactUsGenericForm_Comments: DEV_INSERT_DEFAULTS ? "Testmessage" : "",
      ContactUsGenericForm_CustomerId: DEV_INSERT_DEFAULTS ? "112233445566" : "",
      ContactUsGenericForm_AcceptTerms: DEV_INSERT_DEFAULTS,
    },
  })

  const sanitizeField = (text: string): string => {
    return text.replaceAll("\r\n", "\\n").replaceAll("\n", "\\n").replaceAll('"', '\\"')
  }

  const replacePlaceholder = (text: string, value: ValidationSchemaType): string => {
    return text
      .replaceAll("${CustomerId}", sanitizeField(value.ContactUsGenericForm_CustomerId ?? ""))
      .replaceAll("${Title}", sanitizeField(value.ContactUsGenericForm_Title))
      .replaceAll("${FirstName}", sanitizeField(value.ContactUsGenericForm_FirstName))
      .replaceAll("${LastName}", sanitizeField(value.ContactUsGenericForm_LastName))
      .replaceAll("${Email}", sanitizeField(value.ContactUsGenericForm_Email))
      .replaceAll("${Company}", sanitizeField(value.ContactUsGenericForm_CompanyName ?? ""))
      .replaceAll("${Phone}", sanitizeField(value.ContactUsGenericForm_Phone))
      .replaceAll("${Message}", sanitizeField(value.ContactUsGenericForm_Comments))
      .replaceAll("${ExtendedTopic}", sanitizeField(value.ContactUsGenericForm_ExtendedTopic ?? ""))
  }

  const getEmailTextBody = (value: ValidationSchemaType) => {
    return replacePlaceholder(props.emailTextBody, value)
  }

  const getEmailHtmlBody = (value: ValidationSchemaType) => {
    const richtextReplaced = replacePlaceholder(JSON.stringify(props.emailHtmlBody), value)
    const body = JSON.parse(richtextReplaced)

    return renderToStaticMarkup(
      <EmailTemplateContact
        body={body}
        image={props.emailImage}
        footerLeft={props.emailFooterLeft}
        footerRight={props.emailFooterRight}
        footerBottom={props.emailFooterBottom}
      />
    )
  }

  const sendEmails = (value: ValidationSchemaType) => {
    const emailTextBody = getEmailTextBody(value)
    const emailHtmlBody = getEmailHtmlBody(value)

    const externalEmail = () =>
      sendContactEmail({
        from: props.fromEmail,
        to: value.ContactUsGenericForm_Email,
        subject: props.emailSubject,
        text: emailTextBody,
        html: emailHtmlBody,
      })

    const internalEmail = () =>
      sendContactEmail({
        from: props.fromEmail,
        to: props.toEmail,
        subject: props.emailSubject,
        text: emailTextBody,
        html: emailHtmlBody,
      })

    if (DEV_SEND_EMAILS) {
      Promise.all([externalEmail(), internalEmail()])
        .then(() => redirect(true))
        .catch((error) => {
          console.log(`UbContactUsGenericFormClient: error sending emails: ${error}`)
          redirect(false)
        })
    } else {
      console.log("UbContactUsGenericFormClient: email delivery is disabled by development switch DEV_SEND_EMAILS")
      redirect(true)
    }
  }

  const redirect = (succeeded: boolean) => {
    try {
      succeeded ? router.push(props.httpRedirect) : router.push(props.httpRedirectError)
    } catch (error) {
      console.log(`UbContactUsGenericFormClient: error on redirecting: ${error}`)
    }
  }

  const onSubmit: SubmitHandler<ValidationSchemaType> = (data: ValidationSchemaType, event) => {
    event && event.preventDefault()
    const action = "submit_contact_form" // be careful - seems not to accept spaces etc.
    setSending(true)

    if (executeRecaptcha) {
      executeRecaptcha(action)
        .then((token) => {
          verifyReCaptcha(token, action, props.topic)
            .then((result) => {
              result ? sendEmails(data) : redirect(false)
            })
            .catch((error) => {
              console.log(`UbContactUsGenericFormClient: error executing verifyReCaptcha: ${error.message}`)
              redirect(false)
            })
        })
        .catch((error) => {
          console.log(`UbContactUsGenericFormClient: error executing recaptcha: ${error.message}`)
          redirect(false)
        })
    } else {
      console.log("UbContactUsGenericFormClient: execute recaptcha not available yet")
      redirect(false)
    }
  }

  const styleWithoutOutline =
    "focus:ring-offset-0 focus-visible:ring-offset-0 focus:ring-0 focus:outline-0 focus-visible:outline-0 focus-visible:ring-0"
  const focusBorders = "focus:border-primary focus:border-2 focus-visible:border-primary focus-visible:border-2"
  const textFieldStyleBase = styleWithoutOutline + " " + focusBorders + " pl-4"
  const textFieldStyle = textFieldStyleBase + " pt-1.5 rounded-lg"
  const textFieldStyleMultiline = textFieldStyleBase + " pt-3 rounded-lg"
  const radioButtonStyle = styleWithoutOutline + " " + focusBorders + " size-5"
  const checkboxStyle = styleWithoutOutline + " " + focusBorders + " mr-4 size-5"

  return (
    <>
      <div className="flex flex-col-reverse lg:flex-row">
        <div
          className={cn("bg-muted px-10 pb-6 pt-10 lg:w-[57%] xl:px-20", {
            "pointer-events-none opacity-30 cursor-wait": sending,
          })}
        >
          <div className="mb-6">{renderedRichText(props.description)}</div>
          <p className={"mb-10 text-sm text-muted-foreground"}>
            <span className={"text-primary"}>*</span> {t("forms.contact_us_generic_form.required")}
          </p>
          <Form {...form}>
            <form
              className="flex flex-col"
              ref={formRef}
              name="ContactUsGenericForm"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <fieldset className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
                <input type="hidden" name="ContactUsGenericForm_Topic" value={props.topic} />

                {props.showExtended && props.extendedOptions["tbody"] && props.extendedOptions["tbody"].length > 0 && (
                  <FormField
                    control={form.control}
                    name="ContactUsGenericForm_ExtendedTopic"
                    render={({ field }) => (
                      <FormItem className="mb-4 flex w-full flex-col items-start lg:col-span-2">
                        <FormControl>
                          <RadioGroup
                            className="flex flex-row"
                            defaultValue={field.value}
                            onValueChange={field.onChange}
                          >
                            <div className="flex flex-col items-start justify-start gap-1">
                              {props.extendedOptions["tbody"].map((item, i) => (
                                <div
                                  key={item.body[0].value}
                                  className="flex flex-row items-center justify-center gap-2"
                                >
                                  <RadioGroupItem
                                    className={radioButtonStyle}
                                    {...field}
                                    id={item.body[0].value}
                                    value={item.body[0].value}
                                  />
                                  <label htmlFor={item.body[0].value}>{item.body[1].value}</label>
                                </div>
                              ))}
                            </div>
                            <span className="text-sm text-primary">*</span>
                          </RadioGroup>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_Title"
                  render={({ field }) => (
                    <FormItem className="flex flex-col items-start lg:col-span-2">
                      <FormControl>
                        <RadioGroup className="flex flex-row" defaultValue={field.value} onValueChange={field.onChange}>
                          <div className="flex flex-row items-center justify-center gap-2">
                            <div className="flex flex-row items-center justify-center gap-2">
                              <RadioGroupItem
                                className={radioButtonStyle}
                                {...field}
                                id="salutationMs"
                                value={t("salutation.ms")}
                              />
                              <label htmlFor="salutationMs">{t("salutation.ms")}</label>
                            </div>
                            <div className="flex flex-row items-center justify-center gap-2">
                              <RadioGroupItem
                                className={radioButtonStyle}
                                {...field}
                                id="salutationMr"
                                value={t("salutation.mr")}
                              />
                              <label htmlFor="salutationMr">{t("salutation.mr")}</label>
                            </div>
                            <div className="flex flex-row items-center justify-center gap-2">
                              <RadioGroupItem
                                className={radioButtonStyle}
                                {...field}
                                id="salutationDivers"
                                value={t("salutation.divers")}
                              />
                              <label htmlFor="salutationDivers">{t("salutation.divers")}</label>
                            </div>
                          </div>
                          <span className="text-sm text-primary">*</span>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_FirstName"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-1"}>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.first_name")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_LastName"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-1"}>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.last_name")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_Phone"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-1"}>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          type={"tel"}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.phone")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_Email"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-1"}>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          type={"email"}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.email")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {isB2BCustomer && (
                  <FormField
                    control={form.control}
                    name="ContactUsGenericForm_CompanyName"
                    render={({ field }) => (
                      <FormItem className={"lg:col-span-1"}>
                        <FormControl>
                          <Input
                            className={textFieldStyle}
                            {...field}
                            placeholder={t("forms.contact_us_generic_form.placeholder.company_name")}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_CustomerId"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-1"}>
                      <FormControl>
                        <Input
                          className={textFieldStyle}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.customer_id")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_Comments"
                  render={({ field }) => (
                    <FormItem className={"lg:col-span-2"}>
                      <FormControl>
                        <Textarea
                          className={textFieldStyleMultiline + " h-32"}
                          {...field}
                          placeholder={t("forms.contact_us_generic_form.placeholder.comments")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ContactUsGenericForm_AcceptTerms"
                  render={({ field }) => (
                    <FormItem className="mb-6 lg:col-span-2">
                      <FormControl>
                        <div className="flex flex-row items-center">
                          <Checkbox
                            id="ContactUsGenericForm_AcceptTerms"
                            className={checkboxStyle}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <label className="text-sm" htmlFor="ContactUsGenericForm_AcceptTerms">
                            {renderedRichText(props.legalConsentNotification)}
                            <span className="text-sm text-primary">*</span>
                          </label>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </fieldset>
              <Button type="submit" size="lg" className="mb-6 self-center" disabled={sending}>
                {t("forms.contact_us_generic_form.send")}
              </Button>
              <p className={"mb-6 text-sm"}>{renderedRichText(props.captchaHint)}</p>
            </form>
          </Form>
        </div>
        <div className="flex flex-row bg-primary p-10 lg:max-w-[43%] lg:flex-col">
          {props.title && <div className="mb-8 w-full md:w-3/5 lg:w-full">{renderedRichText(props.title)}</div>}
          {props.image && (
            <div className="hidden md:block md:max-w-[40%] lg:max-w-none">
              <StoryblokAssetClient className=" object-scale-down" asset={props.image} loading="eager" />
            </div>
          )}
        </div>
      </div>
      {/* only used for development */}
      {DEV_SHOW_EMAIL_TEMPLATE && (
        <div
          dangerouslySetInnerHTML={{
            __html: getEmailHtmlBody(form.getValues()),
          }}
        />
      )}
    </>
  )
}

export default UbContactUsGenericFormClient
