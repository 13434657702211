import React from "react"
import { StoryblokComponent } from "@storyblok/react/rsc"
import { useTranslations } from "next-intl"

import { BasketContext } from "@/lib/storefront/basket/basket"

interface BasketEmptyProps {
  blok?: any
}

export default function BasketEmpty(props: BasketEmptyProps) {
  const t = useTranslations("storefront")

  return <div className={"w-full"}>{props.blok && <p> {props.blok["empty_content_text"]} </p>}</div>
}
