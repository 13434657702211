"use client"

import React from "react"
import { deleteLineItemAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { TrashIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { Button } from "@/components/ui/components"

export function BasketLineItemActions(props: { lineItem: BasketLineItemData }) {
  const t = useTranslations("storefront")
  const { setBasketData } = useUbStorefrontState()

  function deleteLineItemHandler(lineItem: BasketLineItemData) {
    if (lineItem.id) {
      deleteLineItemAction(lineItem.id).then((basketData) => {
        if (basketData) {
          setBasketData(basketData)
        }
      })
    }
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Button variant={"secondary_light"} size={"smNoGrow"} onClick={() => deleteLineItemHandler(props.lineItem)}>
        <div className={"flex flex-row gap-1"}>
          <TrashIcon className={"size-4"} />
        </div>
      </Button>
      {/* <Button variant={"secondary_light"} size={"smNoGrow"}>
        <div className={"flex flex-row gap-1"}>
          <NotebookPen className={"size-4"}/>
        </div>
      </Button>*/}
    </div>
  )
}
