import { formatDateLegacy } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { ReducedProductData } from "@/lib/storefront/product/product"

export function calculateAvailabilityText(productData: ReducedProductData | undefined, t: any): string {
  const availableFrom = availableFromDate(productData)
  if (availableFrom) {
    return t("product.available_from", { availableFrom: availableFrom })
  }

  let min = productData?.readyForShipmentMin
  let max = productData?.readyForShipmentMax

  if (temporaryDeliveryTime(productData)) {
    min = productData?.temporaryDeliveryTime
    max = productData?.temporaryDeliveryTime
  }

  if (productData?.availability === false || !min || !max) {
    return t("product.out_of_stock")
  }

  if (getCountry() === "ch") {
    min = Math.max(min, 7)
    max = Math.max(max, 7)
  }

  if (min <= 2) {
    return t("product.shipment_within48")
  } else if (min <= 3) {
    return t("product.shipment_days", { shipment: "2-3" })
  } else if (min <= 5) {
    return t("product.shipment_days", { shipment: "3-5" })
  } else if (min <= 7) {
    return t("product.shipment_days", { shipment: "5-7" })
  } else if (min <= 10) {
    return t("product.shipment_days", { shipment: "7-10" })
  } else if (min <= 15) {
    return t("product.shipment_days", { shipment: "10-15" })
  } else if (min <= 20) {
    return t("product.shipment_days", { shipment: "15-20" })
  } else if (min <= 25) {
    return t("product.shipment_weeks", { shipment: "4-5" })
  } else if (min <= 30) {
    return t("product.shipment_weeks", { shipment: "5-6" })
  } else if (min <= 35) {
    return t("product.shipment_weeks", { shipment: "6-7" })
  } else if (min <= 40) {
    return t("product.shipment_weeks", { shipment: "7-8" })
  } else if (min <= 45) {
    return t("product.shipment_weeks", { shipment: "8-9" })
  } else if (min <= 50) {
    return t("product.shipment_weeks", { shipment: "9-10" })
  } else {
    return t("product.shipment_later")
  }
}

export function availableFromDate(productData: ReducedProductData | undefined): string | undefined {
  return productData?.availability === true &&
    productData.availableFrom !== undefined &&
    new Date() < productData.availableFrom
    ? formatDateLegacy(productData.availableFrom)
    : undefined
}

export function temporaryDeliveryTime(productData: ReducedProductData | undefined): boolean {
  return (
    productData?.availability === true &&
    productData?.temporaryDeliveryTime !== undefined &&
    productData.temporaryDeliveryTimeTill !== undefined &&
    new Date() < productData.temporaryDeliveryTimeTill
  )
}
