import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@next+third-parties@14.2.23_next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Root","displayName"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@radix-ui+react-label@2.1.1_@types+react-dom@18.3.1_@types+react@18.3.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@radix-ui/react-label/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@storyblok+react@3.0.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/@storyblok+react@3.0.15_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next-intl@3.26.3_next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/node_modules/.pnpm/next@14.2.23_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/account/AccountNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/account/profile/email/AccountProfileEmail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/auth/signup/AccountSignUpToggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/basket/BasketOverview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/basket/BasketQuickOrder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/checkout/addresses/CheckoutAddresses.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/checkout/review/CheckoutReview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/AddToBasketDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/ExitDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/InfoBarClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/InitialCustomerTypeDialogClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/layout/Loading.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductDetailBuyboxAddToBasketButton"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxAddToBasketButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxOfferFeaturePopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxPrice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxScalePrice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxShipmentHint.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductDetailBuyboxShowVariantsButton"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/buybox/ProductDetailBuyboxShowVariantsButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BadgeFilterSelector"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/filter/BadgeFilterSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FilterNavigation"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/filter/FilterNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/PdpPageTrackingClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/ProductDetailAccessories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/ProductDetailAnchorNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/ProductDetailImageArea.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/ProductDetailShortDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/pdp/ProductDetailVariantTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductCardAddToBasket.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductCardPrice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/product/ProductClickTrackingClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/CategoryBrandsTrackingClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/CategoryDescription.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/FacetList.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/FilterButtonClient.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/ProductImpressionsTrackingClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/SearchResultPageSize.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/SearchResultPaginationClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/search-and-category/SearchResultSortOrder.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/common/GoogleReCaptchaWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/common/ImageWithFallback.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/header/StoryblokSearchBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbCatalogueOrderForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UBCheckoutHeaderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbContactUsGenericFormClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UbGridShowMoreButton"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbGridShowMoreButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UBHeaderClient.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbLoginForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UbNewsletterSubscriptionFormClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/ub-storefront-next/source/storefront/src/components/storyblok/UBProductImageGallery.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/checkbox.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/command.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/dialog.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/form.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/navigation-menu.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/popover.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/radio-group.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/scroll-area.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/select.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/separator.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/switch.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/opt/ub-storefront-next/source/storefront/src/components/ui/components/toggle.tsx");
