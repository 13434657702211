import { FileDown, NotebookPenIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"

type BasketActionsProps = {
  blok?: any
  isB2B: boolean
}

export default function BasketActions(props: BasketActionsProps) {
  const t = useTranslations("storefront")

  return (
    <div className={"flex flex-row gap-2"}>
      {props.isB2B && props.blok && (
        <a href={"/" + props.blok["save_pdf_path"]}>
          <Button variant={"outline_secondary"} size={"defaultNoGrow"}>
            <div className={"flex flex-row items-center gap-1"}>
              <p>Warenkorb als PDF speichern</p>
              <FileDown />
            </div>
          </Button>
        </a>
      )}
      {props.isB2B && (
        <Button variant={"outline_secondary"} size={"defaultNoGrow"}>
          <div className={"flex flex-row items-center gap-1"}>
            <p>Angebot erstellen</p>
            <NotebookPenIcon />
          </div>
        </Button>
      )}
      {/*      <Button variant={"outline"} size={"smNoGrow"}>
        Bestellvorlage anlegen
      </Button>*/}
    </div>
  )
}
