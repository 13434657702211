"use client"

import React, { useState } from "react"
import { addArticleToCurrentBasketAction, addPromoCodeToCurrentBasketAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ShoppingCart } from "lucide-react"
import { useTranslations } from "next-intl"

import { BasketContext } from "@/lib/storefront/basket/basket"
import { Button, Input } from "@/components/ui/components"

interface BasketPromoCodeProps {}

export default function BasketPromoCode(props: BasketPromoCodeProps) {
  const t = useTranslations("storefront")

  const [promoCode, setPromoCode] = useState<string | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const { setBasketData } = useUbStorefrontState()

  function addPromoCode(promoCode: string) {
    if (promoCode) {
      addPromoCodeToCurrentBasketAction(promoCode).then((result) => {
        setBasketData(result.basketData)
        if (result.result === "NOK") {
          setErrorMessage(result.message)
        }
      })
    }
  }

  const handleChange = (event) => {
    const { value } = event.target
    setPromoCode(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrorMessage(undefined)
    if (promoCode && promoCode !== "") {
      addPromoCode(promoCode)
    }
  }

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <h2 className={"text-lg font-bold"}>Rabattcode</h2>
        <form className={"flex flex-row items-center gap-2"} onSubmit={handleSubmit}>
          <Input type="text" name="productId" value={promoCode} onChange={handleChange} />

          <Button aria-label={t("product.add_to_basket")} size={"productCard"} onClick={handleSubmit}>
            Rabattcode Einlösen
          </Button>
        </form>
        {errorMessage && <p className={"text-xs text-red-500"}>{errorMessage}</p>}
      </div>
    </>
  )
}
