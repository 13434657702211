"use client"

import React, { Suspense, useEffect } from "react"
import { loadBasketLineItemsAction, loadBasketPromotionCodesAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { Skeleton } from "@/components/ui/components"
import BasketActions from "@/components/basket/BasketActions"
import BasketEmpty from "@/components/basket/BasketEmpty"
import BasketPromoCode from "@/components/basket/BasketPromoCode"
import BasketQuickOrder from "@/components/basket/BasketQuickOrder"
import BasketRecentlyViewedProducts from "@/components/basket/BasketRecentlyViewedProducts"
import BasketSummary from "@/components/basket/BasketSummary"
import { BasketLineItems } from "@/components/basket/overview/BasketLineItems"
import BasicCard from "@/components/common/BasicCard"

interface BasketOverviewProps {
  blok: any
  isB2B: boolean
  userData?: UserData
}

export default function BasketOverview(props: BasketOverviewProps) {
  const { basketData, basketLineItems, setBasketLineItems, setBasketPromotionCodes } = useUbStorefrontState()

  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)
  }, [basketData, basketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(props.isB2B, props.userData, basketData).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          //console.dir(lineItems, {depth: 9})

          setBasketLineItems(lineItems)
        }
      })
    }
  }, [basketData, props.isB2B, setBasketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          //console.dir(promotionCodes, {depth: 9})
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, props.isB2B, setBasketPromotionCodes])

  return (
    <div className={"flex min-h-96 w-full flex-col gap-2"}>
      <Suspense>
        <>
          {basketData &&
          (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
          basketLineItems &&
          basketLineItems.length > 0 ? (
            <>
              <div className={"grid w-full grid-cols-1 gap-2 lg:grid-cols-3"}>
                <div className={"col-span-2"}>
                  <div className={"flex flex-col gap-2"}>
                    <BasketLineItems isB2B={props.isB2B} basketLineItems={basketLineItems} />
                  </div>
                </div>
                <div className={"col-span-1"}>
                  <BasicCard className={"sticky top-[7.5rem] min-h-64 lg:top-[8.5rem]"}>
                    <BasketSummary isB2B={props.isB2B} basketContent={props.blok} />
                  </BasicCard>
                </div>
              </div>
            </>
          ) : (
            <div>
              {(basketData && (basketData?.data?.totalProductQuantity ?? 0) < 1) || basketData === undefined ? (
                <BasketEmpty blok={props.blok} />
              ) : (
                <div className="flex flex-row gap-4">
                  <Skeleton className="h-96 w-2/3" />
                  <Skeleton className="h-96 w-1/3" />
                </div>
              )}
            </div>
          )}
        </>
      </Suspense>

      <div className={"grid w-full grid-cols-1 gap-2 lg:grid-cols-3"}>
        <div className={"col-span-2"}>
          <div className={"grid w-full grid-cols-1 gap-8 lg:grid-cols-2"}>
            <div className={"w-full"}>
              <div className={"min-h-32"}>
                <BasketPromoCode />
              </div>
            </div>

            <div className={"w-full"}>
              <div className={"min-h-32"}>
                <BasketQuickOrder isB2B={props.isB2B} />
              </div>
            </div>
          </div>

          <div className={"w-full"}>
            <BasketActions blok={props.blok} isB2B={props.isB2B} />
          </div>
        </div>
      </div>

      <div className={"w-full"}>
        <BasketRecentlyViewedProducts />
      </div>
    </div>
  )
}
