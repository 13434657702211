import React from "react"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { CardPrices } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { calculateAvailabilityText } from "@/components/ui/helpers/product-availability-helper"

//This component renders prices in accessories and variant list on PDP
export function ProductDetailProductListPrice(props: {
  pdpContext: PdpContext
  productData: ReducedProductData | undefined
  cardPrices: CardPrices
  showPrice: boolean
}) {
  const t = useTranslations("storefront")

  const ecoTax: number | undefined = props.productData?.ecoTax
  const ecoTaxFormatted = formatPrice(ecoTax, props.productData?.currency)
  const isB2BCustomer = props.pdpContext.isB2B

  const showEcoTax = getCountry() === "fr" && !!ecoTax && ecoTax > 0
  const priceText = props.pdpContext.isB2B ? t("product.price_net") : t("product.price_gross")
  const ecoTaxText = priceText + t("product.eco_tax", { ecoTax: ecoTaxFormatted })

  const packagingUnitInfo = props.productData?.packagingUnitInfoCompact ?? ""
  const pricePerUnitText = props.productData?.pricePerUnitText ?? ""
  const savingsPercent = props.productData?.savingsPercent ?? 0

  const availabilityTextBase = calculateAvailabilityText(props.productData, /*props.standardVariant,*/ t)
  const availabilityText = availabilityTextBase ? "(" + availabilityTextBase + ")" : ""

  //console.dir(props.productData)

  return (
    <div className={"flex flex-col  items-start sm:items-end"}>
      {props.showPrice ? (
        <div className={"flex flex-row justify-end gap-2"}>
          <div className={"flex flex-col items-end"}>
            {showEcoTax && <p className="text-nowrap text-xs text-gray-400">{ecoTaxText}</p>}

            {pricePerUnitText !== "" && (
              <p className="text-nowrap text-xs text-gray-400" dangerouslySetInnerHTML={{ __html: pricePerUnitText }} />
            )}
            {packagingUnitInfo !== "" && (
              <p
                className="text-nowrap text-xs text-gray-400"
                dangerouslySetInnerHTML={{ __html: packagingUnitInfo }}
              />
            )}

            {props.cardPrices.listPriceFormatted > props.cardPrices.salePriceFormatted || savingsPercent > 0 ? (
              isB2BCustomer ? (
                <div className="flex flex-row items-baseline justify-end gap-1">
                  {!props.cardPrices.isSinglePrice && (
                    <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
                  )}
                  {props.cardPrices.listPriceFormatted !== props.cardPrices.salePriceFormatted && (
                    <p className="text-sm font-bold text-gray-500 line-through">
                      {props.cardPrices.listPriceFormatted}
                    </p>
                  )}
                </div>
              ) : (
                <p className={"text-nowrap text-xs font-bold text-primary"}>{t("basket.add_dialog.special_offer")}</p>
              )
            ) : (
              isB2BCustomer && (
                <div className="flex flex-row items-baseline justify-end gap-1">
                  <p className="text-sm font-bold text-transparent line-through">-</p>
                </div>
              )
            )}

            <div className="flex flex-row items-baseline justify-end gap-1">
              {!props.cardPrices.isSinglePrice && (
                <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
              )}
              <p className="text-xl font-bold text-primary">
                {props.cardPrices.salePriceFormatted}
                {/*{isB2BCustomer ? "*" : ""}*/}
              </p>
            </div>
          </div>

          <div className={"flex items-end pb-2"}>
            <TooltipProvider delayDuration={50} skipDelayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className={"size-3 rounded-full bg-green-600 "} />
                </TooltipTrigger>
                <TooltipContent>
                  <p>{availabilityText}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <p className={"text-nowrap text-base font-bold text-primary"}>{t("product.ask_price")}</p>
        </>
      )}
      {/*
      <textarea className={"hidden"} defaultValue={JSON.stringify(cardPrices)}/>
*/}
    </div>
  )
}
