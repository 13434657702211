"use client"

import React, { useMemo, useState } from "react"
import { updateBasketItemQuantityAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { MinusIcon, PlusIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { BasketLineItemData } from "@/lib/storefront/basket/basket"
import { cn } from "@/components/ui/helpers/utils"
import { BasketLineItemActions } from "@/components/basket/overview/BasketLineItemActions"
import { BasketLineItemPriceInfo } from "@/components/basket/overview/BasketLineItemPriceInfo"
import BasicCard from "@/components/common/BasicCard"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"

export function BasketLineItemCard(props: { isB2B: boolean; lineItem: BasketLineItemData }) {
  const t = useTranslations("storefront")

  const productDataAddedToBasket = props.lineItem.product
  const [quantityIsAboutToChange, setQuantityIsAboutToChange] = useState<boolean>(false)
  const { setBasketData } = useUbStorefrontState()

  const [quantity, setQuantity] = useState(props.lineItem.quantity)

  const variationAttributesAsString = useMemo(() => {
    const attributeArray: string[] | undefined = props.lineItem.product?.variableVariationAttributes?.map(
      (variationAttribute) => {
        return variationAttribute.name + ": " + variationAttribute.value
      }
    )

    return attributeArray?.join(", ")
  }, [props.lineItem])

  const [inputQuantity, setInputQuantity] = useState<string>(props.lineItem.quantity + "")

  function updateBasketItemQuantityHandler(newQuantity: number) {
    if (productDataAddedToBasket) {
      setQuantityIsAboutToChange(true)
      updateBasketItemQuantityAction(productDataAddedToBasket?.id, newQuantity).then((basketData) => {
        setQuantityIsAboutToChange(false)
        if (basketData) {
          setBasketData(basketData)
        }
      })
    }
  }

  function handleQuantityIncrease() {
    const newQuantity = quantity + (productDataAddedToBasket?.stepOrderQuantity ?? 1)
    setInputQuantity(newQuantity + "")
    setQuantity(newQuantity)
    updateBasketItemQuantityHandler(newQuantity)
  }

  function handleQuantityDecrease() {
    if (quantity > (productDataAddedToBasket?.stepOrderQuantity ?? 1)) {
      const newQuantity = quantity - (productDataAddedToBasket?.stepOrderQuantity ?? 1)
      setInputQuantity(newQuantity + "")
      setQuantity(newQuantity)
      updateBasketItemQuantityHandler(newQuantity)
    }
  }

  function updateQuantity(wantedQuantity: number) {
    console.log("Update quantity: " + wantedQuantity)
    if (!(wantedQuantity > 0)) {
      return
    }

    const quantitySteps = productDataAddedToBasket?.stepOrderQuantity
    const minimumOrderQuantity = productDataAddedToBasket?.minOrderQuantity
    if (quantitySteps !== undefined && minimumOrderQuantity !== undefined) {
      const newAddableQuantity = wantedQuantity - (wantedQuantity % quantitySteps)
      const newQuantity = Math.max(minimumOrderQuantity, newAddableQuantity)
      setQuantity(newQuantity)
      setInputQuantity(newQuantity + "")
      updateBasketItemQuantityHandler(newQuantity)
    }
  }

  const changeHandler = (e) => {
    //console.log("value changed: ", e.target.value)
    const inputValue = e.target.value.trim().toLowerCase() //.replace(/\D/,'')
    setInputQuantity(inputValue)
  }

  const blurHandler = () => {
    //console.log("input blurred")
    const intQuantity = parseInt(inputQuantity)
    updateQuantity(intQuantity)
  }

  return (
    <>
      <div className={cn("min-h-32 rounded-none border-0 border-gray-500 p-0 py-2 sm:p-0 sm:py-4")}>
        <div className={"grid grid-cols-6 gap-2 lg:grid-cols-12"}>
          <div className={"col-span-2"}>
            <div className={"flex h-full flex-col justify-between gap-2"}>
              <BasicCard className={"flex aspect-square min-h-1 items-center justify-center p-2"}>
                <ImageWithFallback
                  className={"aspect-square h-20 object-contain"}
                  src={props.lineItem.product.image ?? ""}
                  width={80}
                  height={80}
                  alt={props.lineItem.product.title}
                />
              </BasicCard>
              <div>{/*  <BasketLineItemDeliveryInfo productData={props.lineItem.product}/>*/}</div>
            </div>
          </div>
          <div className={"col-span-4 lg:col-span-6"}>
            <div className={"flex h-full flex-col justify-between gap-2"}>
              <div>
                <p className={"text-xs font-bold text-muted-foreground"}>{props.lineItem.product.brand}</p>
                <p className={"text-sm font-bold"}>{props.lineItem.product.title}</p>
                <p className={"text-xs"}>
                  {t("product.article_number")}:{props.lineItem.product.id}
                </p>
                <div className={"flex flex-row"}>
                  <p className={"text-xs font-bold text-muted-foreground"}>{variationAttributesAsString}</p>
                </div>
              </div>
              <div className={"flex flex-row gap-2"}>
                <div>
                  <div
                    className={cn(
                      "flex w-auto min-w-32 shrink-0 flex-row items-center justify-center rounded-lg bg-gray-100 p-0 px-2 lg:w-auto"
                    )}
                  >
                    <button
                      className={cn("w-auto rounded-full py-1 pl-4 pr-2")}
                      onClick={handleQuantityDecrease}
                      disabled={quantityIsAboutToChange}
                      aria-label={t("product.quantity_decrease")}
                    >
                      <MinusIcon className={"size-5"} />
                    </button>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      aria-label={t("product.quantity")}
                      disabled={quantityIsAboutToChange}
                      className={cn(
                        "h-9 w-12 border-transparent bg-transparent p-0 py-1 text-center text-lg font-bold focus:rounded-md focus:border-2 focus:border-blue-400 focus:outline-none focus:ring-transparent"
                      )}
                      value={inputQuantity}
                      onChange={changeHandler}
                      onBlur={blurHandler}
                      onKeyDown={(event) => {
                        if (
                          !(
                            /[0-9]/.test(event.key) ||
                            event.code === "Backspace" ||
                            event.code === "Delete" ||
                            event.code === "Delete" ||
                            event.code === "ArrowLeft" ||
                            event.code === "ArrowRight"
                          )
                        ) {
                          event.preventDefault()
                        }
                      }}
                    />
                    <button
                      className={cn("w-auto rounded-full py-1 pl-2 pr-4")}
                      onClick={handleQuantityIncrease}
                      disabled={quantityIsAboutToChange}
                      aria-label={"product.quantity_increase"}
                    >
                      <PlusIcon className={"size-5"} />
                    </button>
                  </div>
                </div>
                <BasketLineItemActions lineItem={props.lineItem} />
              </div>
            </div>
          </div>
          <div className={"col-span-4 col-start-3 lg:col-start-9"}>
            <BasketLineItemPriceInfo isB2B={props.isB2B} lineItem={props.lineItem} />
          </div>
        </div>
      </div>
    </>
  )
}
