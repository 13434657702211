import { getCountry, getCurrency, getIsoLocale } from "./link-utils/get-sb-locale"
import { logger } from "./logger"

export async function loadInParallel<T1, T2>(f1: Promise<T1>, f2: Promise<T2>): Promise<[T1, T2]> {
  const promises = [f1, f2]

  const parallelResult = await Promise.allSettled(promises)
  let result1: any
  if (parallelResult[0].status === "fulfilled") {
    result1 = parallelResult[0].value
  }
  let result2: any
  if (parallelResult[1].status === "fulfilled") {
    result2 = parallelResult[1].value
  }
  return [result1, result2]
}

// throws an exception if any of the promises fails
export async function loadInParallel5<T1, T2, T3, T4, T5>(
  f1: Promise<T1>,
  f2: Promise<T2>,
  f3: Promise<T3>,
  f4: Promise<T4>,
  f5: Promise<T5>
): Promise<[T1, T2, T3, T4, T5]> {
  const promises = [f1, f2, f3, f4, f5]

  const results = await Promise.allSettled(promises)

  return results.map((res, index) => {
    if (res.status === "fulfilled") {
      return res.value
    } else {
      logger.warn(`Promise ${index} failed with reason ${res.reason}`)
      throw res.reason
    }
  }) as [T1, T2, T3, T4, T5]
}

export function formatPrice(price?: number, currency?: string): string {
  if (price !== undefined && currency) {
    return new Intl.NumberFormat(getIsoLocale(), {
      style: "currency",
      currency: currency,
    }).format(price)
  }
  return ""
}

export function formatDate(date?: Date): string {
  if (date !== undefined) {
    return new Intl.DateTimeFormat(getIsoLocale(), {}).format(date)
  }
  return ""
}

export function formatDateLegacy(date?: Date): string {
  if (date !== undefined) {
    return new Intl.DateTimeFormat("de-DE", { dateStyle: "short" }).format(date)
  }
  return ""
}

export function formatAndReplacePrices(stringWithUnformattedPrices: string): string {
  if (!stringWithUnformattedPrices) {
    return ""
  }

  const currency = getCurrency()
  const formatted = stringWithUnformattedPrices.replace(/\b\d+\.\d{2}\b/g, (match) => {
    const price = parseFloat(match)
    return formatPrice(price, currency)
  })

  return formatted
}

export function getLastElement(subject: string, separator: string): string {
  return subject.split(separator).slice(-1)[0]
}

export function showContentCountryDependent(countriesToExclude: any) {
  const currentCountry = getCountry()
  const currentCountryExcluded: boolean = countriesToExclude?.includes(currentCountry) || false
  return !currentCountryExcluded
}

export function truncateString(str: string, length: number = 100): string {
  return str.length > length ? str.slice(0, length) + "..." : str
}

export async function readStreamBody(stream: ReadableStream) {
  const reader = stream.getReader()
  const chunks: Uint8Array[] = []
  let result

  while (!(result = await reader.read()).done) {
    chunks.push(result.value)
  }

  return new TextDecoder().decode(Buffer.concat(chunks))
}

export function getString(str: any): string {
  if (typeof str == "string") {
    return str as string
  }
  return ""
}

function getNumber(str: any): number {
  if (typeof str == "number") {
    return str as number
  }
  return -9.99
}

export function getBoolean(str: any): boolean {
  if (typeof str == "boolean") {
    return str as boolean
  }
  if (typeof str == "string") {
    return str.toLowerCase() == "true"
  }
  return false
}

export function round(number: number) {
  return Math.floor((number + Number.EPSILON) * 100) / 100
}
