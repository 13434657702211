import { useEffect, useMemo } from "react"
import Link from "next/link"
import { removePromoCodeFromCurrentBasketAction } from "@/actions/basketActions"
import { HANDLING_FEE_THRESHOLD } from "@/constants"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { XCircleIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { BasketTotalData } from "@/lib/storefront/basket/basket"
import { determineBasketTotalData } from "@/lib/storefront/basket/basketHelper"
import { Button } from "@/components/ui/components"

interface BasketSummaryProps {
  isB2B: boolean
  basketContent: any
}

export default function BasketSummary(props: BasketSummaryProps) {
  const t = useTranslations("storefront")

  const { basketData, basketLineItems, basketPromotionCodes, setBasketData } = useUbStorefrontState()

  const basketTotalData: BasketTotalData = useMemo(() => {
    //console.dir(basketData,{depth:5})
    return determineBasketTotalData(props.isB2B, basketData, basketLineItems, basketPromotionCodes)
  }, [basketData, basketLineItems, basketPromotionCodes, props.isB2B])

  useEffect(() => {
    console.dir(basketTotalData)
    console.dir(basketPromotionCodes)
  }, [basketPromotionCodes, basketTotalData])

  const showHandlingFeeHint = useMemo(() => {
    return getCountry() === "de" && basketTotalData && !props.isB2B && basketTotalData.paymentCostAmountGross > 0
  }, [props.isB2B, basketTotalData])

  function removePromoCode(promoCode: string) {
    removePromoCodeFromCurrentBasketAction(promoCode).then((result) => {
      setBasketData(result.basketData)
    })
  }

  return (
    <>
      {basketData && basketTotalData.totalProductQuantity > 0 ? (
        <div className={"flex flex-col gap-2"}>
          <h2 className={"text-lg font-bold"}>Zusammenfassung</h2>
          {/* <div className="flex w-full items-center justify-between">
          <Image src="/img/baer_transparent.png" alt="Logo" width={40} height={40}/>
          <span className="text-lg font-bold">
            {basketTotalData.totalProductQuantity ?? ""} {t(`common.${basketTotalData.totalProductQuantity === 1 ? "item" : "items"}`)}
          </span>
        </div>*/}

          {basketData && basketTotalData.totalProductQuantity && basketTotalData.totalProductQuantity > 0 && (
            <div className="grid grid-cols-2 text-sm">
              {basketData.data?.totals?.itemTotal?.net && (
                <>
                  <span className={"text-nowrap  font-bold"}>Positionssumme (netto)</span>
                  <span className="text-right  font-bold">
                    {formatPrice(basketTotalData.itemTotalNet, basketTotalData.currency)}
                  </span>
                </>
              )}

              {basketTotalData.ecoTaxTotal > 0 && (
                <>
                  <span className={"text-nowrap"}>{t("header.shopping_basket.eco_tax")}</span>
                  <span className="text-right">
                    {<>{formatPrice(basketTotalData.ecoTaxTotal, basketTotalData.currency)}</>}
                  </span>
                </>
              )}
              {basketTotalData.totalSavingsGeneralNet && basketTotalData.totalSavingsGeneralNet > 0.009 ? (
                <>
                  <span className={"text-nowrap"}>Rahmenrabatt (netto):</span>
                  <span className="text-right text-green-500">
                    {formatPrice(-1 * basketTotalData.totalSavingsGeneralNet, basketTotalData.currency)}
                  </span>
                </>
              ) : (
                <>
                  <span></span>
                  <span></span>
                </>
              )}

              {basketTotalData.totalSavingsSpecialNet && basketTotalData.totalSavingsSpecialNet > 0.009 ? (
                <>
                  <span className={"text-nowrap"}>Sonderrabatt (netto):</span>
                  <span className="text-right text-green-500">
                    {formatPrice(-1 * basketTotalData.totalSavingsSpecialNet, basketTotalData.currency)}
                  </span>
                </>
              ) : (
                <>
                  <span></span>
                  <span></span>
                </>
              )}

              {basketTotalData.promotionCode && basketData.data?.totals?.shippingTotal?.net && (
                <>
                  <div className={"flex flex-row items-center gap-1"}>
                    <span className={"text-nowrap"}>Gutscheincode: {basketTotalData.promotionCode}</span>
                    <Button
                      onClick={() => removePromoCode(basketTotalData.promotionCode ?? "")}
                      variant={"ghost"}
                      size={"tiny"}
                    >
                      <XCircleIcon className={"size-4"} />
                    </Button>
                  </div>
                  <span className="text-right text-green-500">
                    {formatPrice(basketTotalData.promotionAmountNet, basketTotalData.currency)}
                  </span>
                </>
              )}

              {basketData.data?.totals?.grandTotal?.net && (
                <>
                  <span className={"mt-2 text-nowrap font-bold"}>Zwischensumme (netto)</span>
                  <span className="mt-2 text-right font-bold">
                    {formatPrice(basketTotalData.grandTotalNet, basketTotalData.currency)}
                  </span>
                </>
              )}

              <span className="text-nowrap ">Mehrwertsteuer 19%</span>
              <span className="text-right font-bold">
                {formatPrice(basketTotalData.totalTax, basketTotalData.currency)}
              </span>

              {basketData.data?.totals?.shippingTotal?.net && (
                <>
                  <span className={"text-nowrap"}>Versandkosten</span>
                  <span className="text-right font-bold text-green-500">
                    {formatPrice(basketTotalData.shippingTotalNet, basketTotalData.currency)}
                  </span>
                </>
              )}

              {!props.isB2B && basketTotalData.paymentCostAmountGross > 0 && (
                <>
                  <div className="  text-nowrap ">Abwicklungspauschale</div>
                  <span className="  text-right ">
                    {formatPrice(basketTotalData.paymentCostAmountGross, basketTotalData.currency)}
                  </span>
                </>
              )}

              <div className="mt-2 flex items-center text-nowrap font-bold">Summe der Lieferartikel (brutto)</div>
              <span className="mt-2 text-right text-lg font-bold text-primary">
                {formatPrice(basketTotalData.grandTotalGross, basketTotalData.currency)}
              </span>

              <span className="text-nowrap ">Gesamtersparnis (brutto)</span>
              <span className="text-right font-bold text-green-500">
                {formatPrice(-1 * basketTotalData.totalSavingsGross, basketTotalData.currency)}
              </span>
            </div>
          )}

          <Link prefetch={false} className={"cursor-pointer"} href={"/basket/checkout/addresses"}>
            <Button
              size={"defaultNoGrow"}
              variant={"default"}
              className={"w-full bg-primary py-0 font-normal text-white"}
            >
              {"Zur Kasse"}
            </Button>
          </Link>

          {showHandlingFeeHint && (
            <p className={"text-xs text-gray-400"}>{props.basketContent?.handlingFeeHint ?? ""}</p>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  )
}
