import { useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons"
import * as Select from "@radix-ui/react-select"
import { EditIcon } from "lucide-react"

import { ExtendedAddressRO } from "@/lib/storefront/checkout/checkout"
import { cn } from "@/components/ui/helpers/utils"
import { AddressCard } from "@/components/checkout/addresses/AddressCard"
import BasicCard from "@/components/common/BasicCard"

interface SelectAddressProps {
  initialAddress: ExtendedAddressRO
  addresses: ExtendedAddressRO[]
  onEdit?: (address: ExtendedAddressRO) => void
}

const SelectAddress = (props: SelectAddressProps) => {
  const [selectedAddress, setSelectedAddress] = useState(props.initialAddress)

  const handleChange = (value: string) => {
    const newAddress = props.addresses.find((address) => address.id === value)
    newAddress && setSelectedAddress(newAddress)
  }

  const addressTextShort = (a: ExtendedAddressRO) =>
    a.firstName + " " + a.lastName + ", " + a.addressLine1 + ", " + a.postalCode + " " + a.city

  const handleEdit = () => {
    props.onEdit && props.onEdit(selectedAddress)
  }

  return (
    <Select.Root
      defaultValue={props.initialAddress.id}
      value={selectedAddress.id}
      onValueChange={(value: string) => handleChange(value)}
    >
      <BasicCard className={"flex min-h-0 flex-row gap-x-6"}>
        <AddressCard address={selectedAddress} />
        <div className={cn("flex w-full flex-col items-end justify-between gap-y-6", { "justify-end": !props.onEdit })}>
          {props.onEdit && (
            <button
              // className="focus-visible:outline-primary focus-visible:outline-1 focus:outline-primary focus:outline-1"
              onClick={handleEdit}
            >
              <EditIcon className="size-8" />
            </button>
          )}
          <Select.Trigger className="focus:outline-1 focus:outline-primary focus-visible:outline-1 focus-visible:outline-primary">
            <Select.Icon className="">
              <ChevronDownIcon className="size-8" />
            </Select.Icon>
          </Select.Trigger>
        </div>
      </BasicCard>
      <Select.Portal>
        <Select.Content position={"popper"} align={"start"}>
          <Select.ScrollUpButton>
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className="relative my-2 flex flex-col gap-y-2 rounded-sm border bg-white p-2 text-sm drop-shadow-2xl sm:p-4">
            {props.addresses.map((address) => (
              <Select.Item
                className="cursor-pointer focus:outline-1 focus:outline-primary focus-visible:outline-1 focus-visible:outline-primary"
                value={address.id ?? ""}
                key={address.id}
              >
                <Select.ItemText>
                  <div className="p-1">{addressTextShort(address)}</div>
                </Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton>
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

export default SelectAddress
