import { useTranslations } from "next-intl"

import { cn } from "@/lib/utils"

interface UBCheckoutHeaderItemProps {
  blok: any
  icon: any
  name: string
  muted: boolean
}

export function UBCheckoutHeaderItem({ blok, ...restProps }: UBCheckoutHeaderItemProps) {
  const t = useTranslations("storefront")
  return (
    <>
      <restProps.icon
        className={cn("size-12", { "text-muted-foreground": restProps.muted, "text-primary": !restProps.muted })}
      />
      <div className={cn("hidden lg:block", { "text-muted-foreground": restProps.muted })}>
        {t("checkout.header.item." + restProps.name)}
      </div>
    </>
  )
}
