import Image from "next/image"
import { useTranslations } from "next-intl"

import { SuggestionItem } from "@/lib/factfinder/factfinder-interfaces"
import { formatPrice } from "@/lib/helper"
import { getCurrency } from "@/lib/link-utils/get-sb-locale"
import { appendQueryParameter } from "@/lib/link-utils/link-utils"

export default function StoryblokSuggestionResultItem({
  query,
  suggestion,
}: {
  query: string
  suggestion: SuggestionItem
}) {
  const t = useTranslations("storefront")
  const suggestionLink = appendQueryParameter(suggestion.link, "suggest", "true")
  const priceEmpty = !suggestion?.price || isNaN(suggestion.price) || suggestion.price < 0.01
  const showPrice = !priceEmpty
  const isProduct = suggestion.type === "productName"

  const highlightSearchTerm = (text: string, term: string) => {
    const regex = new RegExp(`(${term})`, "gi")
    const parts = text.split(regex)
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span className="font-bold" key={index}>
            {part}
          </span>
        )
      } else {
        return part
      }
    })
  }
  const highlightedDescription = highlightSearchTerm(suggestion.name, query)

  return (
    <a href={suggestionLink} className="flex h-14 px-2.5 text-lg hover:bg-[#19466d] hover:text-white">
      {suggestion.image ? (
        <div className="size-14 border border-gray-300">
          <Image
            unoptimized={true}
            className="size-full object-contain"
            src={suggestion.image}
            alt={suggestion.name ?? ""}
            width={52}
            height={52}
          ></Image>
        </div>
      ) : (
        <></>
      )}
      <span className="my-auto w-full px-2.5">{highlightedDescription}</span>
      <p className="my-auto text-right text-sm font-bold">
        {isProduct && (showPrice ? formatPrice(suggestion.price, getCurrency()) : t("product.ask_price"))}
      </p>
    </a>
  )
}
