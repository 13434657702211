"use client"

import React from "react"
import { useUbPdpState } from "@/store/UbpdpState"

import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { FullMasterProductData, ReducedProductData } from "@/lib/storefront/product/product"
import { showProductPrice } from "@/lib/storefront/product/productHelper"
import { cn } from "@/components/ui/helpers/utils"
import { UseFilteredVariantsHook } from "@/components/product/pdp/UseFilteredVariantsHook"

import { AddToBasketWithQuantityInputOptional } from "./AddToBasketWithQuantityInputOptional"

const TODO_SHOW_ADD_TO_ORDER_TEMPLATE_BUTTON = false

export function ProductDetailBuyboxAddToBasketButton(props: { pdpContext: PdpContext; priceRequestLink: string }) {
  const article: ReducedProductData | undefined = props.pdpContext.productAndArticle.article
  const masterProduct: FullMasterProductData | undefined = props.pdpContext.productAndArticle.masterProduct

  const { useClientSelectedFilterOptions } = useUbPdpState()

  const cardPrices: CardPrices = determineCardPrices(props.pdpContext.isB2B, props.pdpContext.prices, article?.ecoTax)
  const { showPrice, needsPriceQuotation: needsPriceQuotation } = showProductPrice(article, cardPrices)
  const needsDeliveryQuotation = article?.availability === false
  const priceRequestHref =
    (needsPriceQuotation || needsDeliveryQuotation) && article?.id ? props.priceRequestLink + article.id.toString() : ""

  const filteredVariants = UseFilteredVariantsHook(masterProduct).filteredVariants
  if (filteredVariants.length !== 1 && !article) {
    return null
  }

  return (
    <div
      className={cn("flex w-full flex-row items-center justify-start gap-4 py-2", {
        hidden: !useClientSelectedFilterOptions,
      })}
    >
      {TODO_SHOW_ADD_TO_ORDER_TEMPLATE_BUTTON && ( //ToDO implementation in UBW-466 https://udobaer.atlassian.net/browse/UBW-466
        <ProductDetailBuyboxAddToBasketButton pdpContext={props.pdpContext} priceRequestLink={props.priceRequestLink} />
      )}

      <AddToBasketWithQuantityInputOptional
        localState={false}
        productSku={article?.id!}
        article={article}
        prices={props.pdpContext.prices?.data?.[0]}
        minOrderQuantity={article?.minOrderQuantity}
        orderQuantitySteps={article?.stepOrderQuantity}
        showPrice={showPrice}
        needsPriceQuotation={needsPriceQuotation}
        needsDeliveryQuotation={needsDeliveryQuotation}
        priceRequestHref={priceRequestHref}
      />
    </div>
  )
}
