"use client"

import React, { useEffect } from "react"
import {
  createOrderFromCurrentBasketAction,
  loadBasketLineItemsAction,
  loadBasketPromotionCodesAction,
} from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { OrderRO } from "@ub/order-client"
import { useTranslations } from "next-intl"

import { CheckoutContext } from "@/lib/storefront/checkout/checkout-service"
import { Button } from "@/components/ui/components"
import BasketSummary from "@/components/basket/BasketSummary"
import { BasketLineItems } from "@/components/basket/overview/BasketLineItems"
import BasicCard from "@/components/common/BasicCard"

interface CheckoutReviewProps {
  checkoutContext: CheckoutContext
}

export default function CheckoutReview(props: CheckoutReviewProps) {
  const t = useTranslations("storefront")
  const { setCreatedOrder } = useUbStorefrontState()

  const checkoutContext = props.checkoutContext
  const isB2B=props.checkoutContext.isB2B

  const { basketData, basketLineItems, setBasketLineItems, setBasketPromotionCodes } = useUbStorefrontState()

  useEffect(() => {
    console.dir(basketLineItems)
    console.dir(basketData)
  }, [basketData, basketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketLineItemsAction(checkoutContext.isB2B, checkoutContext.userData, basketData).then((lineItems) => {
        if (lineItems) {
          lineItems.sort((a, b) => (a.position > b.position ? 1 : -1))
          //console.dir(lineItems, {depth: 9})

          setBasketLineItems(lineItems)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setBasketLineItems])

  useEffect(() => {
    if (basketData) {
      loadBasketPromotionCodesAction().then((promotionCodes) => {
        if (promotionCodes) {
          //console.dir(promotionCodes, {depth: 9})
          setBasketPromotionCodes(promotionCodes)
        }
      })
    }
  }, [basketData, checkoutContext.isB2B, setBasketPromotionCodes])

  function createOrder() {
    createOrderFromCurrentBasketAction().then((createdOrder: OrderRO | undefined) => {
      setCreatedOrder(createdOrder)
    })
  }

  return (
    <>
      {props.checkoutContext.userData && (
        <>
          <div className={"grid w-full grid-cols-1 gap-2 lg:grid-cols-3"}>
            <div className={"col-span-2"}>
              <div className={"flex flex-col gap-2"}>
                <h1>Ihre Bestelldaten prüfen</h1>
                <p>
                  Prüfen Sie die Details Ihrer Bestellung und nehmen Sie, falls notwendig, noch Änderungen vor. Klicken
                  Sie auf &#34;Bestellung absenden&#34;, um den Bestellvorgang abzuschließen.
                </p>

                <div className={"flex w-full flex-col gap-2"}>
                  <h2>Adressen</h2>
                  <div className={"flex w-full flex-row justify-between gap-2"}>
                    <div className={"flex flex-col gap-2"}>Lieferadresse</div>
                    <div className={"flex flex-col gap-2"}>Rechnungsadresse</div>
                  </div>
                </div>

                <div className={"flex w-full flex-col gap-2"}>
                  <h2>Zahlungsart</h2>
                  <div className={"flex w-full flex-row justify-between gap-2"}>
                    <div className={"flex flex-col gap-2"}>Kauf auf Rechnung</div>
                  </div>
                </div>

                <div className={"flex w-full flex-col gap-2"}>
                  <h2>Prüfen und Kaufen</h2>
                  <div className={"flex w-full flex-row justify-between gap-2"}>
                    {basketData &&
                    (basketData?.data?.totalProductQuantity ?? 0) > 0 &&
                    basketLineItems &&
                    basketLineItems.length > 0 ? (
                      <>
                        <div className={"flex flex-col gap-2"}>
                          <BasketLineItems isB2B={isB2B} basketLineItems={basketLineItems} />
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>

                <Button
                  onClick={createOrder}
                  size={"defaultNoGrow"}
                  variant={"default"}
                  className={"bg-primary py-0 font-normal text-white"}
                >
                  {"Kostenpflichtig Bestellen"}
                </Button>
              </div>
            </div>
            <div className={"col-span-1"}>
              <BasicCard className={"sticky top-[7.5rem] min-h-64 lg:top-[8.5rem]"}>
                <BasketSummary isB2B={checkoutContext.isB2B} basketContent={undefined} />
              </BasicCard>
            </div>
          </div>
        </>
      )}
    </>
  )
}
