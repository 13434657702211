import { ProductPriceContainerCtnrROV1, ScaledPriceROV1 } from "@ub/pricing-client"
import { getTranslations } from "next-intl/server"

import { formatPrice } from "@/lib/helper"
import { ReducedProductData } from "@/lib/storefront/product/product"

const FR_TAX_FACTOR = 1.2

export interface ScalePrice {
  pricePerUnit: number
  minQuantity: number
  currency?: string
}

export interface CardPrices {
  productId?: string
  listPrice: number
  salePrice: number
  discountPercent: number
  listPriceFormatted: string
  salePriceFormatted: string
  discountPercentFormatted: string
  isSinglePrice?: boolean
  scalePrices?: ScalePrice[]
  quantity?: number
}

export function formatPackagingUnit(t: any, unit: string): string {
  if (unit) {
    return t("packaging_unit." + unit) === "packaging_unit." + unit
      ? t("packaging_unit.PCE")
      : t("packaging_unit." + unit)
  } else {
    return t("packaging_unit.PCE")
  }
}

export async function generatePackagingInfo(article: ReducedProductData | undefined) {
  if (
    article &&
    article.baseUnit &&
    article.baseUnit !== "" &&
    article.packingUnit &&
    article.packingUnit !== "" &&
    article.packingUnit !== article.baseUnit &&
    article.ratioBasePackingUnit > 1
  ) {
    const t = await getTranslations("storefront")
    return t("product.price_disclaimer", {
      packingUnit: formatPackagingUnit(t, article.packingUnit),
      ratioBasePackingUnit: article.ratioBasePackingUnit,
      baseUnit: formatPackagingUnit(t, article.baseUnit),
    })
  }

  return ""
}

export async function generatePackagingInfoCompact(article: ReducedProductData | undefined) {
  if (
    article &&
    article.baseUnit &&
    article.baseUnit !== "" &&
    article.packingUnit &&
    article.packingUnit !== "" &&
    article.packingUnit !== article.baseUnit &&
    article.ratioBasePackingUnit > 1
  ) {
    const t = await getTranslations("storefront")
    return t("product.price_disclaimer_compact", {
      packingUnit: formatPackagingUnit(t, article.packingUnit),
      ratioBasePackingUnit: article.ratioBasePackingUnit,
      baseUnit: formatPackagingUnit(t, article.baseUnit),
    })
  }

  return ""
}

export function generateSkuParams(productIds: string[]) {
  //["123456","654321","4634574"] -> "123456&sku=654321&sku=4634574

  if (productIds.length > 0) {
    return productIds.join("&sku=")
  }

  return ""
}

export function generateBasePriceInfo(article: ReducedProductData | undefined, cardPrices: CardPrices) {
  if (
    article &&
    article.basicPriceQuantity &&
    article.basicPriceUnit &&
    article.basicPriceUnit !== "" &&
    article.totalQuantity &&
    article.currency &&
    article.currency !== "" &&
    article.currency !== "N/A"
  ) {
    //console.log(article.currency)
    const pricePerUnit = cardPrices.salePrice / article.totalQuantity
    return `${formatPrice(pricePerUnit, article.currency)} / ${article.basicPriceQuantity} ${article.basicPriceUnit}`
  }

  return ""
}

export async function generateMinOrderInfo(article: ReducedProductData | undefined) {
  if (
    article &&
    article.minOrderQuantity &&
    article.minOrderQuantity > 0 &&
    article.packingUnit &&
    article.packingUnit !== ""
  ) {
    const t = await getTranslations("storefront")
    return t("product.min_order_quantity", {
      qty: article.minOrderQuantity,
      qty_unit: formatPackagingUnit(t, article.packingUnit),
    })
  }

  return ""
}

export async function generateStepOrderInfo(article: ReducedProductData | undefined) {
  if (article && article.stepOrderQuantity && article.stepOrderQuantity > 1) {
    const t = await getTranslations("storefront")
    return t("product.step_order_quantity", {
      qty: article.stepOrderQuantity,
    })
  }

  return ""
}

export function determineCardPrices(
  isB2BCustomer: boolean,
  prices: ProductPriceContainerCtnrROV1 | undefined,
  ecoTax?: number,
  quantity?: number,
  originalProductId?: string
): CardPrices {
  const effectiveQuantity = quantity !== undefined ? quantity : 1
  const productId = originalProductId ? originalProductId : prices?.data?.[0]?.sku
  if (productId) {
    if (prices?.data?.[0]?.prices?.MinListPrice?.[0]) {
      //if a MinListPrice exists, we assume this is a price object for a master product
      return determineCardPricesForMasterProduct(productId, isB2BCustomer, prices, ecoTax, effectiveQuantity)
    } else {
      return determineCardPricesForSingleArticle(productId, isB2BCustomer, prices, ecoTax, effectiveQuantity)
    }
  } else {
    return {
      productId: "",
      listPrice: 0,
      salePrice: 0,
      discountPercent: 0,
      listPriceFormatted: "-",
      salePriceFormatted: "-",
      discountPercentFormatted: "-",
      isSinglePrice: false,
      scalePrices: [],
      quantity: effectiveQuantity,
    }
  }
}

//finds the index of the effective scale price row based on user selected quantity
function findEffectiveScalePriceIndex(priceData: any, quantity: number): number {
  if (quantity === 1) {
    return 0
  } else {
    if (priceData?.prices?.SalePrice && priceData?.prices?.SalePrice.length > 0) {
      let index = -1
      for (const scaledPrice of priceData?.prices?.SalePrice) {
        if (scaledPrice.minQuantity?.value) {
          const minQuantity = scaledPrice.minQuantity?.value
          if (quantity >= minQuantity) {
            index++
          }
        }
      }
      return index
    } else {
      return 0
    }
  }
}

//Uses the first ListPrice and SalePrice to determine prices for a single article
export function extractCardPriceForSingleArticlePriceData(
  productId,
  isB2BCustomer: boolean,
  priceData: any | undefined,
  ecoTax?: number,
  quantity?: number
) {
  const effectiveQuantity = quantity !== undefined ? quantity : 1
  let scalePriceIndex = findEffectiveScalePriceIndex(priceData, effectiveQuantity)
  //console.dir(priceData,{depth:9})
  //ecoTax needs to be appended in FR
  const ecoTaxNet = ecoTax ? ecoTax : 0
  const ecoTaxGross = ecoTax ? ecoTax * FR_TAX_FACTOR : 0

  const currency = priceData?.prices?.ListPrice?.[0]?.net.currency

  const listPrice: number = isB2BCustomer
    ? (priceData?.prices?.ListPrice?.[scalePriceIndex]?.net.value ?? priceData?.prices?.ListPrice?.[0]?.net.value) +
      ecoTaxNet
    : (priceData?.prices?.ListPrice?.[scalePriceIndex]?.gross.value ?? priceData?.prices?.ListPrice?.[0]?.gross.value) +
      ecoTaxGross

  const salePrice: number = isB2BCustomer
    ? priceData?.prices?.SalePrice?.[scalePriceIndex]?.net.value + ecoTaxNet
    : priceData?.prices?.SalePrice?.[scalePriceIndex]?.gross.value + ecoTaxGross

  const listPriceFormatted = isB2BCustomer ? formatPrice(listPrice, currency) : formatPrice(listPrice, currency)

  const salePriceFormatted = isB2BCustomer ? formatPrice(salePrice, currency) : formatPrice(salePrice, currency)

  const discountPercent: number = salePrice > 0 && listPrice > 0 ? Math.round(100 - (salePrice / listPrice) * 100) : 0

  const discountPercentFormatted = discountPercent + "%"

  const scalePrices: ScalePrice[] = []

  if (priceData?.prices?.SalePrice?.length > 1) {
    for (const scaledPrice of priceData?.prices?.SalePrice) {
      const newScaledPrice = determineScaledPrice(isB2BCustomer, scaledPrice)
      scalePrices.push(newScaledPrice)
    }
  }

  return {
    listPrice: listPrice,
    salePrice: salePrice,
    discountPercent: discountPercent,
    listPriceFormatted: listPriceFormatted,
    salePriceFormatted: salePriceFormatted,
    discountPercentFormatted: discountPercentFormatted,
    isSinglePrice: !productId.toUpperCase().startsWith("E"),
    scalePrices: scalePrices,
    quantity: effectiveQuantity,
  }
}

export function determineCardPricesForSingleArticle(
  productId: string,
  isB2BCustomer: boolean,
  prices,
  ecoTax: number | undefined,
  quantity?: number
) {
  const priceData = prices?.data?.[0]
  return extractCardPriceForSingleArticlePriceData(productId, isB2BCustomer, priceData, ecoTax, quantity)
}

function determineScaledPrice(isB2BCustomer: boolean, scaledPrice: ScaledPriceROV1): ScalePrice {
  const pricePerUnit = isB2BCustomer ? scaledPrice.net?.value : scaledPrice.gross?.value
  const currency = isB2BCustomer ? scaledPrice.net?.currency : scaledPrice.gross?.currency
  const minQuantity = scaledPrice.minQuantity?.value
  return {
    pricePerUnit: pricePerUnit ?? 0,
    currency: currency,
    minQuantity: minQuantity ?? 0,
  }
}

//Uses MinListPrice and MinSalePrice to determine prices for master products
function extractCardPriceFromMasterPriceData(
  productId: string,
  isB2BCustomer: boolean,
  priceData,
  ecoTax: number | undefined,
  quantity?: number
) {
  const effectiveQuantity = quantity !== undefined ? quantity : 1

  const ecoTaxNet = ecoTax ? ecoTax : 0
  const ecoTaxGross = ecoTax ? ecoTax * FR_TAX_FACTOR : 0

  const listPrice = isB2BCustomer
    ? priceData?.prices?.MinListPrice?.[0]?.net.value + ecoTaxNet
    : priceData?.prices?.MinListPrice?.[0]?.gross.value + ecoTaxGross

  const salePrice = isB2BCustomer
    ? priceData?.prices?.MinSalePrice?.[0]?.net.value + ecoTaxNet
    : priceData?.prices?.MinSalePrice?.[0]?.gross.value + ecoTaxGross

  const listPriceFormatted = isB2BCustomer
    ? formatPrice(
        priceData?.prices?.MinListPrice?.[0]?.net.value + ecoTaxNet,
        priceData?.prices?.MinListPrice?.[0]?.net.currency
      )
    : formatPrice(
        priceData?.prices?.MinListPrice?.[0]?.gross.value + ecoTaxGross,
        priceData?.prices?.MinListPrice?.[0]?.gross.currency
      )

  const salePriceFormatted = isB2BCustomer
    ? formatPrice(
        priceData?.prices?.MinSalePrice?.[0]?.net.value + ecoTaxNet,
        priceData?.prices?.MinSalePrice?.[0]?.net.currency
      )
    : formatPrice(
        priceData?.prices?.MinSalePrice?.[0]?.gross.value + ecoTaxGross,
        priceData?.prices?.MinSalePrice?.[0]?.gross.currency
      )

  const discountPercent = salePrice > 0 && listPrice > 0 ? Math.round(100 - (salePrice / listPrice) * 100) : 0

  const discountPercentFormatted = discountPercent + "%"

  return {
    productId: productId,
    listPrice: listPrice,
    salePrice: salePrice,
    discountPercent: discountPercent,
    listPriceFormatted: listPriceFormatted,
    salePriceFormatted: salePriceFormatted,
    discountPercentFormatted: discountPercentFormatted,
    isSinglePrice: false,
    quantity: effectiveQuantity,
  }
}

function determineCardPricesForMasterProduct(
  productId: string,
  isB2BCustomer: boolean,
  prices: ProductPriceContainerCtnrROV1,
  ecoTax: number | undefined,
  quantity?: number
): CardPrices {
  const priceData = prices?.data?.[0]
  return extractCardPriceFromMasterPriceData(productId, isB2BCustomer, priceData, ecoTax, quantity)
}
