"use client"

import React, { useCallback, useMemo, useState } from "react"
import { useSearchParams } from "next/navigation"
import { DEFAULT_SEARCH_RESULT_GROUP_SIZE, DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS } from "@/constants/ui"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from "@radix-ui/react-icons"
import { ProductPriceContainerRO } from "@ub/pricing-client"
import { useTranslations } from "next-intl"

import { generateProductLink } from "@/lib/link-utils/link-utils"
import { extractCardPriceForSingleArticlePriceData } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { showProductPrice } from "@/lib/storefront/product/productHelper"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/components/tooltip"
import { cn } from "@/components/ui/helpers/utils"
import { AddToBasketWithQuantityInputOptional } from "@/components/product/pdp/buybox/AddToBasketWithQuantityInputOptional"
import { ProductDetailProductListPrice } from "@/components/product/pdp/ProductDetailProductListPrice"
import { getPaginationInfo, paginateArray } from "@/components/search-and-category/pagination-info"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"

interface ProductDetailAccessoriesProps {
  pdpContext: PdpContext
  skuPriceMap?: Record<string, ProductPriceContainerRO | undefined>
  title?: string
  priceRequestLink: string
}

function AccessoryCard(props: {
  skuPriceMap: Record<string, ProductPriceContainerRO | undefined> | undefined
  accessory: ReducedProductData
  pdpContext: PdpContext
  priceRequestLink: string
}) {
  const t = useTranslations("storefront")

  const { setIsLoading } = useUbStorefrontState()

  const searchParams = useSearchParams()

  const setLoadingCallback = useCallback(() => {
    setIsLoading(true)
  }, [setIsLoading])

  const variantLink = generateProductLink(props.accessory.title, props.accessory.id, searchParams)

  const prices = props.skuPriceMap?.[props.accessory.id]
  const cardPrices = extractCardPriceForSingleArticlePriceData(props.accessory.id, props.pdpContext.isB2B, prices)
  const { showPrice, needsPriceQuotation } = showProductPrice(props.accessory, cardPrices)
  const needsDeliveryQuotation = props.accessory.availability === false
  const priceRequestHref =
    needsPriceQuotation && needsDeliveryQuotation && props.accessory?.id
      ? props.priceRequestLink + props.accessory.id.toString()
      : ""

  return (
    <div
      className={
        "grid grid-cols-1 gap-2 rounded-sm border border-gray-200 bg-white p-2 hover:border-primary sm:grid-cols-2 sm:pr-6"
      }
    >
      <a onClick={setLoadingCallback} className={"block cursor-pointer"} href={variantLink}>
        <div className={"flex flex-row justify-start gap-2"}>
          <div>
            <TooltipProvider delayDuration={50} skipDelayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ImageWithFallback
                    className={"aspect-square h-20 object-contain"}
                    src={props.accessory.small_image?.url || ""}
                    width={80}
                    height={80}
                    alt={props.accessory.title}
                  />
                </TooltipTrigger>
                <TooltipContent side={"right"} className={"rounded-sm border border-gray-200 bg-white"}>
                  <ImageWithFallback
                    className={"aspect-square h-48 bg-white object-contain"}
                    src={props.accessory.preview_image?.url || ""}
                    width={192}
                    height={192}
                    alt={props.accessory.title}
                  />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            {/*  <img className={"aspect-square h-20 object-contain"} src={props.accessory.small_image?.url} alt={""} />*/}
          </div>

          <div className={"items-left flex flex-col gap-2"}>
            <p className={"text-sm font-bold"}>{props.accessory.title}</p>
            <p className={"text-sm"}>{props.accessory.description}</p>
            <p className={"text-xs"}>
              {t("product.article_number")}:{props.accessory.id}
            </p>
          </div>
        </div>
      </a>

      <div
        className={"flex w-full flex-col items-end justify-end gap-2 sm:justify-between md:flex-row md:items-center"}
      >
        <a onClick={setLoadingCallback} href={variantLink} className={"flex min-w-32 flex-col gap-2"}>
          <ProductDetailProductListPrice
            pdpContext={props.pdpContext}
            cardPrices={cardPrices}
            productData={props.accessory}
            showPrice={showPrice}
          />
        </a>

        <div className={"flex w-auto flex-row items-center justify-end gap-2"}>
          <AddToBasketWithQuantityInputOptional
            localState={true}
            compact={true}
            productSku={props.accessory?.id!}
            article={props.accessory}
            prices={props.skuPriceMap?.[props.accessory.id]}
            minOrderQuantity={props.accessory?.minOrderQuantity}
            orderQuantitySteps={props.accessory?.stepOrderQuantity}
            showPrice={showPrice}
            needsPriceQuotation={needsPriceQuotation}
            needsDeliveryQuotation={needsDeliveryQuotation}
            priceRequestHref={priceRequestHref}
          />
        </div>
      </div>
    </div>
  )
}

export default function ProductDetailAccessories(props: ProductDetailAccessoriesProps) {
  const accessories = props.pdpContext.accessories

  const [page, setPage] = useState(1)

  const t = useTranslations("storefront")

  const pageSize = 10

  const hitcount = useMemo(() => {
    return accessories.length
  }, [accessories])

  const lastPage = useMemo(() => {
    let _lastPage = Math.ceil(hitcount / pageSize)
    if (_lastPage > 0) {
      return Math.ceil(hitcount / pageSize)
    } else {
      return 1
    }
  }, [hitcount])

  const paginationInfo = useMemo(() => {
    return getPaginationInfo(DEFAULT_SEARCH_RESULT_GROUP_SIZE, page, lastPage, DEFAULT_SEARCH_RESULT_VISIBLE_GROUPS)
  }, [lastPage, page])
  const previousPage = useMemo(() => {
    return Math.max(page - 1, 1)
  }, [page])

  const nextPage = useMemo(() => {
    return Math.min(page + 1, lastPage)
  }, [lastPage, page])

  function navigateToPage(page: number) {
    setPage(page)
  }

  return (
    <>
      {accessories && accessories.length > 0 && (
        <>
          <div className={"mt-4 flex flex-col gap-2"}>
            <h2 className={"text-lg font-bold"}>{props.title}</h2>
            {paginateArray(accessories, page, pageSize).map((accessory) => (
              <div key={accessory.id}>
                <AccessoryCard
                  pdpContext={props.pdpContext}
                  skuPriceMap={props.skuPriceMap}
                  accessory={accessory}
                  priceRequestLink={props.priceRequestLink}
                />
              </div>
            ))}

            {lastPage > 1 && (
              <div className={"flex w-full flex-row items-center justify-center"}>
                <div className="flex flex-row gap-x-4">
                  <span
                    onClick={() => navigateToPage(1)}
                    className={cn("pt-[0.19rem]", { "pointer-events-none cursor-pointer text-gray2": page === 1 })}
                    title={t("pagination.first")}
                  >
                    <DoubleArrowLeftIcon className="size-6" />
                  </span>
                  <span
                    onClick={() => navigateToPage(previousPage)}
                    className={cn("pt-[0.19rem]", { "pointer-events-none cursor-pointer text-gray2": page === 1 })}
                    title={t("pagination.previous")}
                  >
                    <ChevronLeftIcon className="size-6" />
                  </span>
                  {paginationInfo.map((piItem) => (
                    <span
                      className={cn("cursor-pointer pt-[0.19rem]", {
                        "size-8 text-center rounded-full bg-primary text-white":
                          piItem.start === page && !(page === lastPage),
                        "size-8 text-center rounded-full bg-gray-200 text-gray-800":
                          piItem.start === page && page === lastPage,
                      })}
                      onClick={() => navigateToPage(piItem.start)}
                      key={"SearchResultGridPage_" + piItem.start}
                      title={
                        piItem.start === piItem.end
                          ? t("pagination.page", { page: piItem.start, total: lastPage })
                          : t("pagination.pages", {
                              page: piItem.start + "-" + piItem.end,
                              total: lastPage,
                            })
                      }
                    >
                      {piItem.start === piItem.end ? piItem.start : piItem.start + "-" + piItem.end}
                    </span>
                  ))}
                  <span
                    className={cn("pt-[0.19rem]", {
                      "pointer-events-none cursor-pointer  text-gray2": page === lastPage,
                    })}
                    onClick={() => navigateToPage(nextPage)}
                    title={t("pagination.next")}
                  >
                    <ChevronRightIcon className="size-6" />
                  </span>
                  <span
                    className={cn("pt-[0.19rem]", {
                      "pointer-events-none cursor-pointer text-gray2": page === lastPage,
                    })}
                    onClick={() => navigateToPage(lastPage)}
                    title={t("pagination.last")}
                  >
                    <DoubleArrowRightIcon className="size-6" />
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
